<template>
    <div class="pre-order-card">
        <div class="pre-order-card__part pre-order-card__part--top">
            <div class="pre-order-card__wrapper pre-order-card__wrapper--content">
                <img
                    :src="image"
                    alt="product image"
                    class="pre-order-card__image"
                />

                <div class="pre-order-card__details">
                    <div class="pre-order-card__article">
                        <span class="pre-order-card__font pre-order-card__font--article">
                            Арт.:&nbsp; {{ article }}
                        </span>
                    </div>

                    <h3 class="pre-order-card__title">
                        <span class="pre-order-card__font pre-order-card__font--title">
                            {{ title }}
                        </span>
                    </h3>

                    <div class="pre-order-card__wrapper pre-order-card__wrapper--additional">
                        <div class="pre-order-card__size">
                            <span class="pre-order-card__font pre-order-card__font--size">
                                Размер: {{ size }}
                            </span>
                        </div>

                        <div class="pre-order-card__color">
                            <span class="pre-order-card__font pre-order-card__font--color">
                                Цвет: {{ color }}
                            </span>
                        </div>
                    </div>

                    <div class="pre-order-card__wrapper pre-order-card__wrapper--controls">
                        <button
                            class="pre-order-card__button pre-order-card__button--control"
                            @click="handleRemove"
                        >
                            <img
                                src="/svg/trash.svg"
                                alt=""
                                class="pre-order-card__icon pre-order-card__icon--control"
                            />
                        </button>

                        <FavoritesToggler
                            :product-id="id"
                            small
                            class="pre-order-card__button pre-order-card__button--control"
                        />
                    </div>
                </div>
            </div>

            <div class="pre-order-card__price">
                <span class="pre-order-card__font pre-order-card__font--price">{{ price }} ₽</span>
            </div>
        </div>

        <div class="pre-order-card__part pre-order-card__part--bottom">
            <div
                v-if="dealer"
                class="pre-order-card__button pre-order-card__button--dealer"
            >
                <span class="pre-order-card__font pre-order-card__font--dealer">{{ dealer }}</span>
            </div>

            <button
                class="pre-order-card__button pre-order-card__button--change"
                :class="{ 'pre-order-card__button--change--selected': dealer }"
                @click="handleChangeDealer"
            >
                <span class="pre-order-card__font pre-order-card__font--change">{{ dealer ? 'Изменить дилера' : 'Выбрать дилерский центр' }}</span>
            </button>
        </div>

        <hr />
    </div>
</template>

<script>
import FavoritesToggler from '@/components/pages/FavoritesToggler.vue';

export default {
    name: 'pre-order-card',
    components: { FavoritesToggler },

    props: {
        article: {
            type: String,
            default: undefined,
        },

        title: {
            type: String,
            default: undefined,
        },

        price: {
            type: String,
            default: undefined,
        },

        image: {
            type: String,
            default: undefined,
        },

        dealer: {
            type: String,
            default: undefined,
        },

        size: {
            type: String,
            default: undefined,
        },

        color: {
            type: String,
            default: undefined,
        },

        id: {
            type: String,
            default: undefined,
        },
    },

    methods: {
        handleRemove() {
            this.$emit('remove');
        },

        handleFavorites() {
            this.$emit('favorites');
        },

        handleChangeDealer() {
            this.$emit('change-dealer');
        },
    },
};
</script>

<style scoped lang="scss">
.pre-order-card {
    &__part {
        &--top {
            display: flex;

            margin-bottom: rem(20px);
        }

        &--bottom {
            display: flex;

            margin-bottom: rem(20px);
        }
    }

    &__wrapper {
        &--content {
            display: flex;
            flex-grow: 1;
        }

        &--additional {
            display: flex;
            gap: rem(19px);

            margin-bottom: rem(14px);
        }

        &--controls {
            display: flex;
            gap: rem(5px);
        }

        &--dealer-button {
        }
    }

    &__image {
        width: rem(100px);
        height: rem(125px);
        margin-right: rem(22px);
    }

    &__details {
        max-width: rem(270px);
        padding-top: rem(23px);
    }

    &__article {
        margin-bottom: rem(7px);

        color: #b8b8b8;
    }

    &__font {
        &--article {
            font-size: rem(8px);
            line-height: rem(7px);
        }

        &--title {
            font-size: rem(16px);
            line-height: rem(14px);
        }

        &--size {
            font-size: rem(8px);
            line-height: rem(7px);
        }

        &--color {
            font-size: rem(8px);
            line-height: rem(7px);
        }

        &--price {
        }

        &--change, &--dealer {
            display: block;

            width: 100%;
            overflow: hidden;

            font-size: rem(14px);
            text-overflow: ellipsis;
        }
    }

    &__title {
        margin-bottom: rem(11px);
    }

    &__size {
    }

    &__color {
    }

    &__button {
        cursor: pointer;

        &--control {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(25px);
            height: rem(25px);
            padding: 0;

            :deep(svg) {
                width: rem(25px);
                height: rem(12px);
            }
        }

        &--dealer {
            position: relative;

            display: flex;
            flex-shrink: 0;
            align-items: center;

            width: rem(294px);
            height: rem(35px);
            margin-right: 46px;
            padding: 0 rem(36px) 0 rem(19px);

            text-align: left;
            color: #fff;
            white-space: nowrap;

            background-color: #00C9BF;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;

                width: 0;
                height: 100%;

                border-top: rem(35px) solid #00c9bf;
                border-right: rem(16px) solid transparent;
            }
        }

        &--change {
            position: relative;

            width: rem(294px);
            height: rem(35px);
            padding: 0 rem(16px) 0 rem(19px);

            text-align: left;
            color: #fff;

            background-color: #000;

            &--selected {
                width: rem(219px);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;

                width: 0;
                height: 100%;

                border-top: rem(35px) solid #000;
                border-right: rem(16px) solid transparent;
            }
        }
    }

    &__icon {
        &--control {
            width: 100%;
            height: 100%;
        }
    }

    &__price {
        padding-top: rem(33px);
    }
}
</style>
