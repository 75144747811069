<template lang="pug">
    .home
        home-intro
        collection-crossway
        home-new-items
        life-style-gallery
        div
            .disc {{this.banners?.[0]?.disclaimer}}
</template>

<script>
import HomeIntro from '@/components/pages/home/Intro';
import HomeNewItems from '@/components/pages/home/NewItems';
import LifeStyleGallery from '@/components/pages/home/LifeStyleGallery';
import { getBanners } from '@/js/api/requests/catalog';
import CollectionCrossway from '@/components/pages/home/CollectionCrossway.vue';

export default {
    name: 'home',

    components: {
        CollectionCrossway,
        HomeIntro,
        HomeNewItems,
        LifeStyleGallery,
    },
    data: () => ({
        banners: [],
    }),
    async mounted() {
        await this.getBanners();
        this.initSlider();
    },
    methods: {
        async getBanners() {
            const data = await getBanners();
            this.banners = data.entries;
        },
    },
};
</script>

<style scoped lang="scss">
.home {
    margin-bottom: rem(60px);
}

.disc {
    width: 90%;
    margin: 30px auto;

    font-size: 10px;
    line-height: 12px;
    color: #444;
}

.home-intro {
    margin-bottom: rem(68px);
}

.collection-crossway {
    margin-bottom: rem(114px);
}

.home-collections {
    margin-bottom: rem(110px);
}

.home-new-items {
    margin-bottom: rem(108px);
}

.life-style {
    margin-bottom: rem(72px);
}

@include mobile_or_P {
    .home {
        margin-bottom: 0;
    }

    .home-intro {
        margin-bottom: rem(42px);
    }

    .collection-crossway {
        margin-bottom: rem(60px);
    }

    .home-collections {
        margin-bottom: rem(60px);
    }

    .home-new-items {
        margin-bottom: rem(50px);
    }
}
</style>
